import { useRouter } from 'next/router';
import { useEffect } from 'react';

import { useAuthContext } from '@contexts/AuthContext';

export default function Index() {
    const router = useRouter();
    const { authLoading, isLoggedIn } = useAuthContext();

    useEffect(() => {
        if (!authLoading && router.isReady) {
            if (isLoggedIn) {
                router.replace('/discover');
            } else if (!isLoggedIn) {
                router.replace('/onboarding');
            }
        }
    }, [authLoading, isLoggedIn, router]);

    return null;
}
